<template>
  <div>
    <button
      class="focus:outline-none hover:text-secondary duration-150"
      @click="modalOpen = true"
    >
      <Icon
        width="24px"
        height="24px"
        view-box="0 0 24 24"
      >
        <Edit />
      </Icon>
    </button>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Edit Candidate Instructions
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditSendExamsTemplate
        v-if="sendExamsTemplate"
        :send-exams-template="sendExamsTemplate"
        :send-exams-template-default="sendExamsTemplateDefault"
        @sendExamsTemplateUpdated="updateSendExamsTemplate"
      />
      <div
        v-else
        class="flex justify-center items-center"
        style="min-height: 605px;"
      >
        <Loader></Loader>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import EditSendExamsTemplate from '@components/Settings/EditSendExamsTemplate'
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'
import Plus from '@components/Icons/Plus'
import Loader from '@components/Loader'

export default {
  components: {
    Modal,
    EditSendExamsTemplate,
    Icon,
    Edit,
    Plus,
    Loader
  },

  props: {
    sendExamsTemplate: {
      type: Object,
      default: null
    },
    sendExamsTemplateDefault: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  mounted() {
    if (this.$route.query.candidateInstructions) {
      this.modalOpen = true
    }
  },

  methods: {
    updateSendExamsTemplate() {
      this.$emit('sendExamsTemplateUpdated')
      this.modalOpen = false
    }
  }
}
</script>
