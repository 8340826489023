<template>
  <div>
    <button
      class="focus:outline-none hover:text-secondary duration-150"
      @click="modalOpen = true"
    >
      <Icon
        width="24px"
        height="24px"
        view-box="0 0 24 24"
      >
        <Edit />
      </Icon>
    </button>

    <Modal
      :open="modalOpen"
      size="max-w-screen-md"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Edit company details
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditCompanyForm @close="modalOpen = false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import EditCompanyForm from '@components/EditCompanyForm'
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Modal,
    EditCompanyForm,
    Icon,
    Edit,
    Plus
  },

  data() {
    return {
      modalOpen: false
    }
  }
}
</script>
